import { notification } from 'antd'
import axios from './index-cms'
import { useQuery, useMutation} from 'react-query'

const getVulnerability = async () => await axios.get('vulnerability/')
const updateVulnerability = async ({id, payload}) => await axios.put(`vulnerability/${id}`, payload)

export function useGetVulnerability() {
    return useQuery(['getVulnerability'], () => getVulnerability())
}

export function useUpdateVulnerability() {
    return useMutation(updateVulnerability, {
        onSuccess: () => {
            notification.success({
                message: 'Success',
                description: 'Vulnerability updated successfully'
            })
        },
        onError: () => {
            notification.error({
                message: 'Error',
                description: 'Vulnerability update failed'
            })
        }
    })
}