import React, { useCallback, useEffect, useState } from 'react'
import NavBar from '../../header/NavBar'
import { ProductOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, notification } from 'antd';
import './ClimateChange.css'
import { getAllClimatesByRegion, getAllTrackedInstancesByOrgUnit, getClimatesByDistrictIdAndRegion, getClimatesByRegion, getReportByDistrictId } from '../service/forcast.service';
import ClimateChangeCarrousel from './ClimateChangeCarrousel';
import moment from 'moment';
import Table from 'react-bootstrap/Table';
import { Pagination } from 'react-bootstrap';


const dataElementId = {
    "Geocoordinates": "L0JvYCSg7nO",
    "Number of affected persons": "UNnbpQMikK5",
    "Recorded value": "DAHtXO5hLw2",
    "Risk Level": "Efous4MOrza",
    "Recorded Photo": "hO2QxM62IeC",
    "Community Remarks": "rltHa1VipBI",
    "Recorded Photo1": "GS1CkiTNZQn",
    "Recorded Photo2": "V15hHhNVs4Z",
    "Recorded Photo3": "M1EGcCp1VH4",
    "Recorded Photo4": "PVB1oKJ9sfD",
    "Recorded Photo5": "yHTZ3xJ1BJg"
}
const { Content, Footer, Sider } = Layout;

const breadcrumbItems = [
    <Breadcrumb.Item key="home">Home</Breadcrumb.Item>,
    <Breadcrumb.Item key="lisa">Lisa</Breadcrumb.Item>,
    <Breadcrumb.Item key="climate">Climate</Breadcrumb.Item>
];

const specifiedDistricts = {
    'Ashanti Region': [
        'Sekyere Afram Plains',
        'Offinso North',
        'Offinso Municipal',
        'Ejura Sekeredumase',
        'Sekyere Kumawu',
        'Adansi South',
    ],
    'Western Region': [
        'Wassa Amenfi East',
        'Ahanta West Municipal',
        'Nzema East',
        'Jomoro',
    ],
    'Greater Accra Region': [
        'Ada East',
    ],
    'Central Region': [
        'Effutu Municipal',
    ],
    'Eastern Region': [
        'Fanteakwa North',
    ],
};

const ClimateChange = () => {
    const [regions, setRegions] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [districtsByRegion, setDistrictsByRegion] = useState({});
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [climateReport, setClimateReport] = useState(null);
    const [climateType, setClimateType] = useState('');
    const [data, setData] = useState([]);
    const [uniqueDates, setUniqueDates] = useState([]);
    const rowsPerPage = 1;
    const [currentPage, setCurrentPage] = useState(1);

    const handleChangePage = (value) => {
        setCurrentPage(value);
    };

    const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    let eventIds = (climateReport?.instances || []).map(event => event.event);
    let dataElementUids = (climateReport?.instances || []).map(event => event.dataValues.map(dataValue => dataValue.dataElement));

    useEffect(() => {
        const fetchRegions = async () => {
            try {
                const data = await getClimatesByRegion()
                setRegions(data.organisationUnits);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDistricts = async () => {
            try {
                const data = await getAllClimatesByRegion();
                setDistricts(data.organisationUnits);
            } catch (error) {
                console.error(error);
            }
        };
        fetchRegions();
        fetchDistricts();
    }, []);

    // Find districts for each region
    const findDistrictsForRegion = useCallback(async () => {
        const districtsByRegionMap = {};
        for (const region of regions) {
            try {
                const data = await getClimatesByDistrictIdAndRegion(region.id);
                const districtNames = data.children.map(districtId => {
                    return districts.find(district => district.id === districtId.id);
                }).filter(Boolean);

                districtsByRegionMap[region.id] = districtNames;
            } catch (error) {
                console.error(error);
            }
        }

        setDistrictsByRegion(districtsByRegionMap);
    }, [regions, districts]);

    useEffect(() => {
        let defaultItemId = "qluibu24aED";
        // display report for default district on page load
        if (!selectedDistrict) {
            getReportByDistrictId(defaultItemId)
                .then(report => {
                    setClimateReport(report);

                    // console.log('Report', JSON.stringify(report))
                })
                .catch(error => {
                    console.error(error);
                    notification.error({
                        message: 'Error',
                        description: 'An error occurred while fetching the report',
                    });
                });
        }
        if (regions.length && districts.length) {
            findDistrictsForRegion();
        }
    }, [regions, districts, selectedDistrict, findDistrictsForRegion]);


    useEffect(() => {
        let newData = [];
        if (climateReport) {
            (climateReport?.instances || []).forEach((event, index) => {
                let eventData = {
                    key: index,
                    eventDate: moment(event.eventDate).format('YYYY-MM-DD'),
                    event: event.event,
                    "Geocoordinates": 0,
                    "Number of affected persons": 0,
                    "Recorded value": 0,
                    "Risk Level": 0,
                    "Community Remarks": 0,
                    "Tracked Entity": event.trackedEntity || 0
                };

                if (event.dataValues.length !== 0) {
                    event.dataValues.forEach((dataValue) => {
                        const key = Object.keys(dataElementId).find(key => dataElementId[key] === dataValue.dataElement);
                        if (key && !key.startsWith('Recorded Photo')) {
                            let value = dataValue.value || 0;
                            eventData[key] = value;
                        }
                    });
                }

                newData.push(eventData);
            });
            setData(newData);
        } else {
            console.log("climateReport is not available");
        }
    }, [climateReport]);

    const items2 = regions.map((item, index) => {
        if (item.id === 'BNvzSyfV6JK') {
            return null;
        }
        const key = String(index + 1);
        const regionDistricts = districtsByRegion[item.id] || [];
        return {
            key: `sub${key}`,
            icon: React.createElement(ProductOutlined),
            label: item.displayName,
            children: regionDistricts.map((districtItem, j) => {
                const subKey = `${index}-${j}`;
                return {
                    key: subKey,
                    label: districtItem.displayName,
                    onClick: async () => {
                        setSelectedDistrict(districtItem);
                        try {
                            const report = await getReportByDistrictId(districtItem.id);
                            const climateTypeResponse = await getAllTrackedInstancesByOrgUnit(districtItem.id);
                            setClimateReport(report);
                            setClimateType(climateTypeResponse?.instances || '');
                        } catch (error) {
                            console.error(error);
                        }
                    },
                };
            }),
        };
    });

    // Filter out items2 and LISA regions Ashanti Region, Western Region, Great Accra Region, Central Region, Eastern Region)
    const items = items2.filter(item => item &&
        (item.label === 'Ashanti Region' || item.label === 'Western Region' || item.label === 'Greater Accra Region' ||
            item.label === 'Central Region' || item.label === 'Eastern Region'));

    const filteredItems = items
        .map(item => ({
            label: item.label,
            key: item.key,
            children: item.children
                ? item.children.filter(child => specifiedDistricts[item.label].includes(child.label))
                : []
        }));

    const columns = [
        {
            title: 'Title',
            dataIndex: 'dataElement',
            width: '17%',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            width: '50%',
        },
        {
            title: 'Event Date',
            dataIndex: 'eventDate',
            width: '30%',
            key: 'eventDate',
            sorter: (a, b) => {
                // Convert dates to numbers and compare
                return new Date(a.eventDate) - new Date(b.eventDate);
            },
            // sortDirections: ['descend', 'ascend'],
            filters: uniqueDates.map(date => ({ text: date, value: date })),
            onFilter: (value, record) => {
                return record.eventDate.startsWith(value);
            },
        },
    ];

    return (
        <div>
            <NavBar />
            <Layout>
                <Content style={{ padding: '0 50px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
                    <Layout className="site-layout-background" style={{ padding: '24px 0', }}>
                        <Sider className="site-layout-background" width={240}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['16-13']}
                                defaultOpenKeys={['sub17']}
                                style={{ height: '100%', }}
                                items={filteredItems}
                            />
                        </Sider>
                        <Content
                            style={{ padding: '0 24px', minHeight: 280, }}
                        >
                            {/* {selectedDistrict && climateReport && climateReport.events.length > 0 && <ClimateChangeCarrousel dataElementId={eventIds} dataElementUids={dataElementUids} currentPage={currentPage} />} */}
                            {climateReport && (climateReport?.instances || []).length > 0 && <ClimateChangeCarrousel dataElementId={eventIds} dataElementUids={dataElementUids} currentPage={currentPage} />}
                            <div style={{ margin: '2rem 2rem' }}>
                                <h3>Lisa Climate Change</h3>
                            </div>
                            {/* {JSON.stringify(data)} */}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        {columns.map((column, index) => (
                                            <th key={index}>{column.title}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((event, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <tr>
                                                <td>Community's Name</td>
                                                <td>
                                                    {climateType && climateType.map((type, index) => (
                                                        <>
                                                            {(type.trackedEntity === event["Tracked Entity"]) &&
                                                                <span>{type?.attributes[0].value}</span>
                                                            }
                                                        </>
                                                    ))
                                                    }
                                                </td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Type of Climate</td>
                                                <td>
                                                    {climateType && climateType.map((type, index) => (
                                                        <>
                                                            {(type.trackedEntity === event["Tracked Entity"]) &&
                                                                <span>{type?.attributes[4]?.value}</span>
                                                            }
                                                        </>
                                                    ))
                                                    }
                                                </td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Geocoordinates</td>
                                                <td>{event.Geocoordinates}</td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Number of affected persons</td>
                                                <td>{event["Number of affected persons"]}</td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Recorded value</td>
                                                <td>{event["Recorded value"]}</td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Risk Level</td>
                                                <td>{event["Risk Level"]}</td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                            <tr>
                                                <td>Community Remarks</td>
                                                <td>{event["Community Remarks"]}</td>
                                                <td>{event.eventDate}</td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                            <Pagination>
                                {Array.from({ length: Math.ceil(data.length / rowsPerPage) }, (_, index) => (
                                    <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => handleChangePage(index + 1)}>
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </Content>
                    </Layout>
                </Content>
                <Footer style={{ textAlign: 'center',}}>AO Holdings © 2024 All rights resverved</Footer>
            </Layout>
        </div>
    )
}

export default ClimateChange;
