import React from 'react'
import Report from './Report';


const DownloadPDF = () => {
    return (
        <div>
            <Report title="SEASONAL FORECAST REPORT"seasonalForecast='Seasonal Forecast' />
        </div>
    )
}

export default DownloadPDF
