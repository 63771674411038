import React from 'react'
import NavBar from '../../header/NavBar'
import VulnerabilityImage from '../../static/images/img/vulnerability.jpg'
import Footer from '../footer/LisaFooter'
import RelatedNews from './RelatedNews'


const Vulnerability = () => {
    return (
        <div>
            <NavBar />
            <div style={{ width: '70%', margin: '0 auto', textAlign: 'justify', marginTop: '5rem', marginBottom: '8rem' }}>
                <h2 style={{ color: '#0B6000', textAlign: 'center', marginTop: '8rem', width: '70%', margin: '0 auto', marginBottom: '2rem' }}>
                    Vulnerability assessment and crowdfunding workshop
                </h2>
                <p style={{fontSize: '1.3rem'}}>
                    The objective of the workshop was to build the capacity of local government officials to conduct rapid climate vulnerability assessments using the LISA platform, which provides climate data evidence and to mainstream the process into their medium-term development plans.
                </p>
                <p style={{fontSize: '1.3rem'}}>
                    To take advantage of the participants present and for efficiency, the workshop included a 1-day workshop on how to mobilize alternative finance using the innovative crowdfunding platform, CHANGO, to implement climate-resilient public investments using the PBCRG system. The event was also used to provide an orientation to the district assemblies on the APA indicators that would be used for the APA later in the year; 60 people attended the workshop.
                </p>
                <div style={{ marginTop: '5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={VulnerabilityImage} width={600} alt='District Development' />
                </div>
            </div>
            <RelatedNews newsItems={[
                { path: '/capacity-building', text: 'Capacity building on the utilization of the Assessing Climate Change Adaptation Framework (ACCAF)' },
                { path: '/training', text: 'Training for the local ACE districts' },
                { path: '/district-development', text: 'District Development Data Platform User’s Forum' },
                { path: '/crowdfunding', text: 'Crowdfunding training for climate-resilient infrastructure ' },
            ]} />
            <Footer />
        </div>
    )
}

export default Vulnerability